<template>
  <div class="popup_wrap" style="width:70%;">
    <button
      class="layer_close"
      @click.prevent="$emit('close')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- Close -->
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONEX010P030.001') }}</h1><!-- 수출 담당자 -->
      <div class="content_box mt10">
        <h2 class="content_title">{{ $t('msg.ONEX010P030.002') }}</h2><!-- 수출 업무 담당 -->
        <table class="tbl_row">
          <colgroup>
            <col width="130">
            <col width="180">
            <col width="130">
            <col width="180">
            <col width="130">
            <col width="*">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX010P030.011') }}</th><!-- 서류마감 -->
              <td colspan="3">{{ $ekmtcCommon.changeDatePattern(vesselInfoDetail.bkgDocCls, '.') }}</td>
              <th>{{ $t('msg.ONEX010P030.012') }}</th><!-- 모선마감 담당자 -->
              <td>{{ vesselInfoDetail.cllPic }} <span v-show="vesselInfoDetail.cllPic && memberDetail.userCtrCd === 'KR' && (parentInfo.polPortCd !== 'DSN' && parentInfo.polPortCd !== 'KPO' && parentInfo.polPortCd !== 'KAN')"> DOCCLL@EKMTC.COM </span></td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX010P030.013') }}</th><!-- VGM 마감 -->
              <td colspan="3">{{ $ekmtcCommon.changeDatePattern(vesselInfoDetail.vgmDocCls, '.') }}</td>
              <th>{{ $t('msg.ONEX010P030.014') }}</th><!-- S/R 마감 담당자 -->
              <td>{{ vesselInfoDetail.srPic }} <span v-if="$ekmtcCommon.isNotEmpty(vesselInfoDetail.srPic) && memberDetail.userCtrCd === 'KR'"> DOCSR@EKMTC.COM </span></td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX010P030.015') }}</th><!-- EDI 신고마감 -->
              <td colspan="3">{{ $ekmtcCommon.changeDatePattern(vesselInfoDetail.bkgMfCls, '.') }}</td>
              <th>{{ $t('msg.ONEX010P030.023') }}</th><!-- 비엘 수정 담당자 -->
              <td>{{ userList.usrNm || '' }} {{ userList.usrPhone || '' }} <span v-show="userList.usrNm && memberDetail.userCtrCd === 'KR'"> DOCCA@EKMTC.COM</span></td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX010P030.017') }}</th><!-- 반입마감 -->
              <td colspan="3">{{ $ekmtcCommon.changeDatePattern(vesselInfoDetail.bkgCgoCls, '.') }}</td>
              <th>{{ $t('msg.ONEX010P030.016') }}</th><!-- 세관마감 담당자 -->
              <td>{{ vesselInfoDetail.ediPic }} <span v-show="vesselInfoDetail.ediPic && memberDetail.userCtrCd === 'KR' && (parentInfo.polPortCd !== 'DSN' && parentInfo.polPortCd !== 'KPO' && parentInfo.polPortCd !== 'KAN')"> DOCEDI@EKMTC.COM </span></td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX010P030.018') }} / {{ $t('msg.CSCT060G030.035') }}</th><!-- MRN / Call Sign -->
              <td>{{ vesselInfoDetail.mrnNo }}</td>
              <th>{{ $t('msg.ONEX010P030.019') }}</th><!-- Call Sign -->
              <td>{{ vesselInfoDetail.clsgNo }}</td>
              <th>{{ $t('msg.SCHD010P040.029') }}</th><!-- 위험물담당자 -->
              <td>{{ vesselInfoDetail.ediPic }} <span v-show="vesselInfoDetail.ediPic && memberDetail.userCtrCd === 'KR' && (parentInfo.polPortCd !== 'DSN' && parentInfo.polPortCd !== 'KPO' && parentInfo.polPortCd !== 'KAN')"> DOCEDI@EKMTC.COM </span></td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX010P030.021') }}</th><!-- CFS 반입마감 -->
              <td colspan="3">{{ vesselInfoDetail.cfsCls }}</td>
              <th>{{ $t('msg.ONEX010P030.024') }}</th><!-- 비엘 발행 담당자 -->
              <td v-if="issManagerList.length > 0"><span v-show="polCtrCd === 'KR'">{{ issManagerList[0].blIssNm || '' }} {{ issManagerList[0].blIssTel || '' }} {{ issManagerList[0].blIssAddr || '' }}</span></td>
              <td v-else-if="beforeIssueBl !== ''"><span v-show="polCtrCd === 'KR'">{{ beforeIssueBl || '' }}</span></td>
              <td v-else></td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX010P030.020') }}</th><!-- 반입지 / 담당자 -->
              <td colspan="5" v-if="parentInfo.polShtmCd !== '02'">{{ vesselInfoDetail.cgoPic }}</td>
              <td colspan="5" v-if="parentInfo.polShtmCd === '02'">
                <p>{{ txtCfsPolTrmlInfo }}</p>
                <font color="red" id="cfsred">{{ $t('msg.CSBK100.087') }}</font>
              </td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">{{ $t('msg.ONEX010P030.003') }}</h2><!-- 수입 업무 담당 -->
        <table class="tbl_col tal">
          <colgroup>
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONEX010P030.004') }}</th><!-- 구분 -->
              <th>{{ $t('msg.ONEX010P030.005') }}</th><!-- 담당자 -->
              <th>{{ $t('msg.ONEX010P030.006') }}</th><!-- TEL -->
              <th>{{ $t('msg.ONEX010P030.007') }}</th><!-- email -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="anStatus === 'N'">{{ $t('msg.ONEX010P030.008') }}</td><!-- 수입 업무 -->
              <td v-else-if="anStatus === 'Y'">{{ $t('msg.ONEX010P030.022') }}</td><!-- D/O 발행 및 정산 -->
              <td>{{ managerInfo.name }}</td>
              <td>{{ managerInfo.telNumber }}</td>
              <td>{{ managerInfo.email }}</td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
    </div>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import arrivalNotice from '@/api/rest/trans/arrivalNotice'
import myRequest from '@/api/rest/trans/myRequest'
import trans from '@/api/rest/trans/trans'
import { managerList } from '@/api/managerList'
import { rootComputed } from '@/store/helpers'
import exportSvc from '@/api/rest/trans/exportSvc'

export default {
  name: 'ExportManagePop',
  components: {
    'close-info': () => import('@/components/trans/CloseInfo.vue')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: '',
          blNo: '',
          vslCd: '',
          voyNo: '',
          polShtmCd: '',
          polPortCd: '',
          podPortCd: '',
          bound: 'O',
          polTrmlCd: '',
          anStatus: '',
          bkgDocCls: '',
          bkgMfCls: '',
          bkgCgoCls: '',
          cfsCls: '',
          rpaCfmYn: ''
        }
      }
     }
  },
  data () {
    return {
      anStatus: this.parentInfo.anStatus,
      rpaCfmYn: this.parentInfo.rpaCfmYn,
      polCtrCd: this.parentInfo.polCtrCd,

      //수출업무 담당자 리스트
      vesselInfoDetail: {},
      vesselInfo: {},

      //수입업무 담당자 리스트
      importInfoDetail: {},
      managerList: [],
      randomList: [],
      managerInfo: {},

      // BL 수정 담당자 조회
      userList: {},

      // BL 발행담당자 조회
      issManagerList: [],
      beforeIssueBl: '',

      txtCfsPolTrmlInfo: '' // CFS 반입지 / 담당자
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const TH = this

      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo)) {
        // [수출 관련]
        // 수출업무 담당 가져오기
        await vslScheduleInfo.getVslScheduleInfo(this.parentInfo).then(response => {
          if (response.status === 200) {
            console.log(response)
            const data = response.data.vesselInfoDetail
            this.vesselInfoDetail = data

            // 반입지 / 담당자 Null 처리
            console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.vesselInfoDetail.mrnNo)
            this.vesselInfoDetail.cgoPic = this.vesselInfoDetail.cgoPic.endsWith('/ ') === true ? this.vesselInfoDetail.cgoPic.replace('/', '') : this.vesselInfoDetail.cgoPic
            this.vesselInfoDetail.mrnNo = (this.$ekmtcCommon.isEmpty(this.vesselInfoDetail.mrnNo)) ? '' : this.vesselInfoDetail.mrnNo + (this.$ekmtcCommon.isNotEmpty(this.vesselInfoDetail.apoTcnt) ? (' / ' + this.vesselInfoDetail.apoTcnt) : '')// MRN+입항횟수

            // 광양,대산,포항일때 담당자
            if (this.$ekmtcCommon.isEmpty(this.vesselInfoDetail.cllPic) && (this.parentInfo.polPortCd === 'DSN' || this.parentInfo.polPortCd === 'KPO' || this.parentInfo.polPortCd === 'KAN')) {
              const cllPlcInfo = TH.plcFnc(this.parentInfo.polPortCd)
              this.vesselInfoDetail.cllPic = `${cllPlcInfo.usrNm} ${cllPlcInfo.usrPhone} ${cllPlcInfo.usrEmail}`
            }

            if (this.$ekmtcCommon.isEmpty(this.vesselInfoDetail.ediPic) && (this.parentInfo.polPortCd === 'DSN' || this.parentInfo.polPortCd === 'KPO' || this.parentInfo.polPortCd === 'KAN')) {
              const ediPlcInfo = TH.plcFnc(this.parentInfo.polPortCd)
              this.vesselInfoDetail.ediPic = `${ediPlcInfo.usrNm} ${ediPlcInfo.usrPhone} ${ediPlcInfo.usrEmail}`
            }
          }
        })

        let apvUno = this.parentInfo.apvUno.length > 0 ? this.parentInfo.apvUno[0].apvUno : ''

        // BL 수정 담당자 조회
        const THIS = this
        await myRequest.getUserInfo({ apvUno: apvUno }).then(res => {
          const vo = res.data
          console.log('getUserInfo >>>> ', res)

          if (vo) {
            THIS.userList = {
              usrNm: vo.usrNm !== undefined ? vo.usrNm : vo.usrEnm,
              usrPhone: vo.usrPhone,
              usrEmail: vo.usrEmail
            }
          }
        })

        // CFS 반입지 / 담당자 조회
        if (this.parentInfo.polShtmCd === '02') {
          const params = {
            polPortCd: this.parentInfo.polPortCd,
            podPortCd: this.parentInfo.podPortCd,
            podCtrCd: this.parentInfo.podCtrCd,
            vslCd: this.parentInfo.vslCd,
            voyNo: this.parentInfo.voyNo,
            polTrmlCd: this.parentInfo.polTrmlCd,
            conCode: 'Y',
            findCntrTypYn: 'Y', // CFS 반입지 / 담당자 조회시 Conatiner 목록 조회 여부
            bkgNo: this.parentInfo.bkgNo
          }
          await trans.getCfsInfo(params).then((res) => {
            const data = res.data
            if (data.cfsRmkYn === 'Y') {
              this.txtCfsPolTrmlInfo =
                this.$t('msg.CSBK100.128') +
                ' : ' +
                (data.cfsNm ? data.cfsNm : '') +
                ' /\n' +
                this.$t('msg.CSBK100.129') +
                ' : ' +
                (data.picNm ? data.picNm : '') +
                ' / TEL : ' +
                (data.telNo ? data.telNo : '') +
                ' / FAX : ' +
                (data.faxNo ? data.faxNo : '') +
                '\n' +
                (data.cfsRmk ? data.cfsRmk : '')
            } else {
              this.txtCfsPolTrmlInfo =
                this.$t('msg.CSBK100.128') +
                ' : ' +
                (data.cfsNm ? data.cfsNm : '') +
                ' /\n' +
                this.$t('msg.CSBK100.129') +
                ' : ' +
                (data.picNm ? data.picNm : '') +
                ' / TEL : ' +
                (data.telNo ? data.telNo : '') +
                ' / FAX : ' +
                (data.faxNo ? data.faxNo : '')
            }
          })
        }

        // 비엘 발행 담당자 조회
        await exportSvc.getBlIssueManagerInfo(this.parentInfo)
          .then(res => {
            console.log('getBlIssueManagerInfo @@@@@', res)

            const list = res.data

            if (this.rpaCfmYn === 'Y') {
              if (this.memberDetail.userCtrCd === 'KR') {
                this.beforeIssueBl = '051-797-7400(내선 6) / DOCIS@EKMTC.COM'
              } else {
                this.beforeIssueBl = '051-797-7400(Ext. 6) / DOCIS@EKMTC.COM'
              }
            } else {
              if (list.length > 0) {
                if (list.length === 1) {
                  this.issManagerList = list
                  if (list[0].blIssNm === 'RPA') {
                    this.issManagerList[0].blIssAddr = 'DOCIS@EKMTC.COM'
                  }
                } else {
                  this.issManagerList = list
                  this.issManagerList[0].blIssTel = list[1].blIssTel
                }
              } else {
                this.beforeIssueBl = 'DOCIS@EKMTC.COM / DESKSEL@EKMTC.COM'
              }
            }
          })

        // A/N 발송전일때
        // if (this.parentInfo.anStatus === 'N') {
        //   // [수입 관련]
        //   // 수입업무 담당자 리스트 가져오기
        //   this.managerList = await managerList.importManagerList

        //   // 국가코드 TW - 랜덤으로 뽑기
        //   this.randomList = this.managerList.filter(item => {
        //     if (item.ctrCd === 'TW' && item.portCd === 'KEL') {
        //       return item
        //     }
        //   })

        //   // podPortCd의 지역코드로 국가코드 가져오기
        //   await commons.getCtrCdList(this.parentInfo.podPortCd).then(res => {
        //     if (res.length > 0) {
        //       for (let i = 0; this.managerList.length; i++) {
        //         if (this.managerList[i].portCd === res[0].plcCd && this.managerList[i].ctrCd === res[0].ctrCd) {
        //           this.managerInfo = this.managerList[i]
        //           break
        //         } else if (this.managerList[i].portCd === 'ALL' && this.managerList[i].ctrCd === res[0].ctrCd) {
        //           this.managerInfo = this.managerList[i]
        //           break
        //         } else if (this.parentInfo.podPortCd === 'KEL' && res[0].ctrCd === 'TW') {
        //           this.managerInfo = this.randomList[Math.floor(Math.random() * this.randomList.length)]
        //           break
        //         }
        //       }
        //     } else {
        //       this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX010P030.009')) // 등록된 국가코드가 없습니다.
        //     }
        //   })
        // } else {
          this.getImportManagerInfo()
        // }
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX010P030.010')) // 필수값이 없습니다.
        this.$emit('close')
      }
    },
    //수입 업무 담당 가져오기
    getImportManagerInfo () {
      const data = {
        blNo: this.$ekmtcCommon.isEmpty(this.parentInfo.blNo) ? this.parentInfo.bkgNo : this.parentInfo.blNo,
        bkgNo: this.parentInfo.bkgNo,
        podCtrCd: this.parentInfo.podCtrCd,
        ntcCd: '05'
      }

      //D/O STATUS가 어떻게 올지 모르기 때문에 일단 Y,N으로 구분
      //Reference :: 수입업무 > 수입일괄처리 > BL검색시 하단의 신고담당자 가져오는 쿼리
      // old : CSDO100Controller.findAnSendRmk 변경하여 사용
      // arrivalNotice.getImportManagerInfo(data)
      //   .then(response => {
      //     this.managerInfo = {
      //       team: response.data.name,
      //       tel: response.data.telNumber,
      //       email: response.data.email
      //     }
      // })
      // if (!this.$ekmtcCommon.isEmpty(data.blNo)) {
        arrivalNotice.getImportManagerInfoV2(data)
          .then(response => {
            // console.log('response @@@@@ ', response.data[0])
            if (!this.$ekmtcCommon.isEmpty(response.data[0])) {
              this.managerInfo = {
                name: response.data[0].name,
                telNumber: response.data[0].telNumber,
                email: response.data[0].email
              }
            }
            // else {
            //   if (this.parentInfo.polPortCd === 'DSN' || this.parentInfo.polPortCd === 'KPO' || this.parentInfo.polPortCd === 'KAN') {
            //     const plcInfo = this.plcFnc(this.parentInfo.polPortCd)

            //     this.managerInfo = {
            //       name: plcInfo.usrNm,
            //       telNumber: plcInfo.usrPhone,
            //       email: plcInfo.usrEmail
            //     }
            //   }
            // }
          })
      // }
    },
    plcFnc (plcCd) {
      let rtnObj = {}
      if (plcCd === 'DSN') {
        rtnObj = {
          usrNm: '대동항업(권성순)',
          usrPhone: '041-664-8645',
          usrEmail: 'daedongagent@daum.net'
        }
      } else if (plcCd === 'KPO') {
        rtnObj = {
          usrNm: '신진해운(최제이)',
          usrPhone: '054-272-5204',
          usrEmail: 'mail@shinjinpohang.co.kr'
        }
      } else if (plcCd === 'KAN') {
        rtnObj = {
          usrNm: '여진해운',
          usrPhone: '061-793-0211',
          usrEmail: 'ops@yeojin.net'
        }
      }
      return rtnObj
    }
  }
}
</script>

<style scoped>
</style>
